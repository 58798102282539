import React, { useState, useEffect } from "react"
import Card from "../components/Card/Card"
import Layout from "../components/Layout"
import { graphql } from "gatsby"

const Blogs = content => {
  const [blogs, setBlogs] = useState(content.data.allMarkdownRemark.edges)
  const [showPerPage, setShowPerPage] = useState(9)
  const [blogsCount, setBlogsCount] = useState(blogs.length / showPerPage)
  const [counter, setCounter] = useState(1)
  const [pageNo, setPageNo] = useState({
    start: 0,
    end: showPerPage,
  })

  useEffect(() => {
    let end = showPerPage * counter
    let start = end - showPerPage
    setPageNo({
      start: start,
      end: end,
    })
    console.log(blogsCount)
  }, [counter])
  return (
    <Layout>
      <div className="blogsContainer" style={{ marginTop: "50px" }}>
        {blogs.slice(pageNo.start, pageNo.end).map(e => {
          return (
            <Card
              key={e.node.id}
              name={e.node.frontmatter.name}
              slug={e.node.frontmatter.slug}
              image={e.node.frontmatter.img}
              date={e.node.frontmatter.date}
            />
          )
        })}
      </div>
      <div className="pagination">
        <button
          onClick={() => setCounter(counter - 1)}
          disabled={counter === 1 ? "true" : ""}
        >
          prev
        </button>
        <div className="pageNumber">
          {counter} of {Math.ceil(blogsCount)}
        </div>
        <button
          onClick={() => setCounter(counter + 1)}
          disabled={counter >= blogsCount ? "true" : ""}
        >
          next
        </button>
      </div>
    </Layout>
  )
}

export default Blogs

export const query = graphql`
  query allpost {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___post }) {
      edges {
        node {
          id
          frontmatter {
            date
            img
            name
            post
            slug
          }
        }
      }
    }
  }
`
