import React from "react"
import "./Card.css"

const Card = ({ name, slug, image, date }) => {
  return (
    <a
      href={`https://www.codinggyan.co.in/blogs/${slug}`}
      className="cardLink"
      target="_blank"
      rel="noreferrer"
    >
      <div className="card">
        <img src={`/blogImages/${image}`} alt={name} />
        <p>{name}</p>
        <p>{date}</p>
      </div>
    </a>
  )
}

export default Card
